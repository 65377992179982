import React, { createRef } from "react";
import "../styles/global.css"
import { Navigation } from '../data'

// Screenshot
import { useScreenshot, createFileName } from "use-react-screenshot";

export default function Layout({children}) {

  // const ref = createRef(null);
  // const [image, takeScreenShot] = useScreenshot({
  //   type: "image/jpeg",
  //   quality: 1.0
  // });

  // const download = (image, { name = "Fleet-Pass-Screenshot", extension = "jpg" } = {}) => {
  //   const a = document.createElement("a");
  //   a.href = image;
  //   a.download = createFileName(extension, name);
  //   a.click();
  // };

  // const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  return (
    <div className="p-4">
    {/* <main className="container mx-auto" ref={ref}> */}
    <main className="container mx-auto min-h-screen">
      {children}
    </main>
    <footer>
      <div class="mx-auto py-12 md:flex md:items-center md:justify-between">
        <div class="flex justify-center space-x-6 md:order-2">
          {Navigation.map((item) => (
            <a key={item.name} href={item.href} target="_blank" class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">{item.name}</span>
              {item.icon}
            </a>
          ))}
        </div>
        <div class="mt-8 md:mt-0 md:order-1">
          <p class="text-center text-base text-gray-400">&copy; Fleet Pass. All rights reserved.</p>
        </div>
      </div>
    </footer>
    {/* <div className="screenshot-gen bg-primary" onClick={downloadScreenshot}> */}
    {/* <div className="screenshot-gen bg-primary">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="black">
        <path fillRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
      </svg>
    </div> */}
    </div>
  )
}